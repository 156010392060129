import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import StarBG from "./rsc/Stars.jpg";
import Logo from "./rsc/Logo.png"; 
import AppleBadge from "./rsc/AppleBadge.svg";
import MicrosoftBadge from "./rsc/MicrosoftBadge.png";
import "./Home.css";
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

import {Icon, Segment, Header, Divider} from "semantic-ui-react";

import Interstitial from "./Interstitial";
import Contest from "./Contest";

const MOBILE_WIDTH = 700;

export default class Home extends React.Component {
    constructor(props){
        super(props);
        this.state = {contest: null};
        this.db = firebase.firestore();
        this.fetchContest();
    }

    fetchContest = () => {
        this.db.collection("contests").orderBy("start", "desc").limit(1).get().then((snap) => {
            if(snap.size == 0) return;
            snap.forEach((doc) => {
                let data = doc.data();
                data.__id = doc.id;
                this.setState({contest: data});
            });
        }, (e) => {
            console.error(e);
        });
    }

    render(){
        const seoStructuredData = (
            <script type="application/ld+json">
                {
                    `
                        {
                            "@context": "https://schema.org",
                            "@type": "SoftwareApplication",
                            "name": "Kartfinity",
                            "operatingSystem": ["OSX", "Windows"],
                            "applicationCategory": "GameApplication",
                            "installUrl": ["https://apps.apple.com/us/app/kartfinity/id1479337138", "https://www.microsoft.com/en-us/p/kartfinity/9nx4z7mjrkj1"],
                            "offers": {
                                "@type": "Offer",
                                "price": "0"
                            }
                        }
                    `
                }
            </script>
        );
        const starStyle = {backgroundImage: "url(" + StarBG + ")", color: "#FFFFFF", position: "relative", textAlign: "center", paddingTop: "2vh"};
        const halfNhalf = {display: "inline-block", width: "50%", padding: "0 40px", verticalAlign: "top"};
        let timeUntilStart = this.state.contest == null ? 0 : (this.state.contest.start.toDate() - (new Date()));
        let timeUntilEnd = this.state.contest == null ? 0 : (this.state.contest.end.toDate() - (new Date()));
        return (
            <div>
                <Helmet>
                    <title>Kartfinity</title>
                    {seoStructuredData}
                </Helmet>
                <div className="fullHeight pageSection" style={{...starStyle}}>
                    {
                        (this.state.contest == null || timeUntilStart > 604800000 || timeUntilEnd < 0) ? null : ( //604800000: a week in millis.
                            <Link to="#contest" style={{color: "#FFFFFF"}}><p style={{fontSize: "3vh", textShadow: "0 0 15px #FEC400DD"}}><Icon name="rocket" />A <strong>building contest</strong> {timeUntilStart < 0 ? (timeUntilEnd < 604800000 ? "is ending soon" : "has started") : "is starting soon"}!</p></Link>
                        )
                    }
                    <img src={Logo} alt="Kartfinity Logo" style={{width: "40%"}} className="absolute_centered" />
                    <Link to="#video"><Icon name="chevron down" className="hovercraft" size="huge" style={{margin: "0 auto", color: "white"}} /></Link>
                </div>
                <Interstitial />
                <div className="pageSection" style={{...starStyle}} id="video">
                    <iframe
                        title="Kartfinity Trailer"
                        style={{border: "#FFFFFF 15px solid", borderRadius: "30px"}}
                        width="933"
                        height="525"
                        src="https://www.youtube.com/embed/-r5WKUODFoc"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <Interstitial />
                <div className="pageSection" style={{...starStyle}} id="features">
                    <div style={{...halfNhalf}}>
                        <Header inverted style={{fontSize: "5vh"}}>Infinite Driving</Header>
                        <p style={{fontSize: "3vh"}}>Drive around a never-ending racetrack with Infinite Mode, featuring tons of unique community-created levels.</p>
                    </div>
                    <div style={{...halfNhalf}}>
                        <Header inverted style={{fontSize: "5vh"}}>Level Creator</Header>
                        <p style={{fontSize: "3vh"}}>Create your own levels to be featured in Infinite Mode! There are no limits except your imagination.</p>
                    </div>
                </div>
                <Interstitial />
                <div className="pageSection" style={{...starStyle}}>
                    <div>
                        <Header inverted style={{fontSize: "5vh"}}>Ready to Play?</Header>
                        <a href="https://apps.apple.com/us/app/kartfinity/id1479337138" target="_blank"><img src={AppleBadge} alt="Download on the Mac App Store" style={{height: "75px"}} /></a>
                        <a href="//www.microsoft.com/store/apps/9nx4z7mjrkj1?cid=storebadge&ocid=badge"><img src={MicrosoftBadge} alt="Get it on from Microsoft" style={{height: "75px", marginLeft: "10px"}}/></a>
                    </div>
                    
                    <p style={{paddingTop: "10px"}}>
                        App Store is a service mark of Apple Inc.
                    </p>
                </div>
                <Interstitial />
                {
                    (this.state.contest == null || timeUntilEnd < 0) ? null :
                    (
                        <div>
                            <div className="pageSection" style={{...starStyle}}>
                                <Contest titleText={timeUntilEnd < 0 ? "Previous Contest" : "Featured Contest"} data={this.state.contest} anchor />
                            </div>
                            <Interstitial />
                        </div>
                    )
                }
                <div style={{width: "100%", padding: "20px", color: "#FFFFFF", backgroundColor: "#000000", textAlign: "center"}}>
                    <p>Thanks for playing! <Icon name="heart" /> View our <Link to="/privacypolicy">privacy policy</Link> <Icon name="heart" /> Made by <a href="http://yoonicode.com">Yoonicode</a></p>
                </div>
            </div>
        );
    }
}