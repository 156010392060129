import React from "react";
import {Header, Divider, Table, Grid, Segment, Dimmer, Loader, Button, List, Form, TextArea, Input, Popup} from "semantic-ui-react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/functions";
import {supportEmail, levelIdify} from "./constants";
import moment from "moment";
import { Helmet } from "react-helmet";
import Error from "./Error";

const timestampFormat = "ddd[,] MMMM Do[,] YYYY [at] h:mm A";

const rejectionReasons = {
    "false-affiliation": {
        description: "Creator name suggests affiliation with Yoonicode",
        reason: "your creator name falsely suggests affiliation with Yoonicode",
        check: (level) => level.creatorName.toLowerCase().includes("yoon")
    },
    "long-name": {
        description: "Level name is too long",
        reason: "your level's name is too long",
        check: (level) => level.name.length > 45
    },
    "long-creator-name": {
        description: "Creator name is too long",
        reason: "your creator name is too long",
        check: (level) => level.creatorName.length > 32
    },
    "spam": {
        description: "Spam or very low effort",
        reason: "your level was deemed spam or very low effort",
        check: (level) => level.tiles.length == 25 //Check against people just building straight from start to finish
    },
    "long-design": {
        description: "Level takes too long to complete",
        reason: "your level would take too long for an average player to complete"
    },
    "incompletable": {
        description: "Level isn't completable",
        reason: "your level was deemed impossible to complete"
    },
    "uses-glitches": {
        description: "Level uses glitches",
        reason: "your level utilizes glitches"
    },
    "laggy": {
        description: "Level causes too much latency",
        reason: "your level would cause too much latency on many players' devices",
        check: (level) => level.tiles.length > 150
    },
    "inappropriate-name": {
        description: "Inappropriate name",
        reason: "your level name isn't appropriate for all ages"
    },
    "inappropriate-creator": {
        description: "Inappropriate creator name",
        reason: "your creator name isn't appropriate for all ages"
    },
    "inappropriate-design": {
        description: "Inappropriate level design",
        reason: "your level design isn't appropriate for all ages"
    },
    "unsupported-localization": {
        description: "Level name is in an unsupported localization",
        reason: "your level name is in a currently unsupported localization (we unfortunately only support English for the time being)"
    },
    "promotional": {
        description: "Level/creator name promotes/advertizes",
        reason: "your level name or creator name promotes, advertizes, or endorses an organization or person"
    },
    "default-name": {
        description: "Level name is unchanged",
        reason: "you haven't given your level a name yet",
        check: (level) => level.name.toLowerCase().includes("custom level #")
    }
};

export default class Moderation extends React.Component {
    constructor(props){
        super(props);
        this.state = {level: null, levelId: null, creator: null, error: "", fetchingCreator: false, reasons: {}, selectedReason: "", message: "", messageSubject: "", tokensGranted: 0, submitting: false, editedName: "", editedCreator: ""};
        this.functions = firebase.functions();
        this.db = firebase.firestore();
        firebase.auth().onAuthStateChanged((user) => {
            if(user == null){
                window.location.replace("/auth?dest=%2Fmoderation");
                return;
            }
            this.user = user;
        });

        this.fetchLevel();
    }

    fetchLevel(){
        this.db.collection("levels").where("modApproved", "==", false).limit(1).get().then((snap) => {
            if(snap.size == 0){
                this.setState({error: "no_documents_found"});
                return;
            }
            let level = snap.docs[0].data();
            let reasons = {};
            for(let key of Object.keys(rejectionReasons)){
                if(rejectionReasons[key].check == undefined){
                    reasons[key] = false;
                    continue;
                }
                reasons[key] = rejectionReasons[key].check(level);
            }
            this.setState({level: level, levelId: snap.docs[0].id, creator: null, fetchingCreator: false, reasons: reasons, selectedReason: "", message: "", messageSubject: "", tokensGranted: 0, editedName: "", editedCreator: ""});
            this.fetchCreatorData();
            return;
        }, (e) => {
            console.error(e);
            if(e.code == undefined){
                this.setState({error: "fetch_failed"});
                return;
            }

            switch(e.code){
                case "permission-denied":
                    this.setState({error: "unauthorized"});
                    return;
                default:
                    this.setState({error: "fetch_failed"});
                    return;
            }
        });
    }

    fetchCreatorData = () => {
        this.setState({fetchingCreator: true});
        this.db.collection("users").doc(this.state.level.creator).get().then((doc) => {
            let newData = doc.data();
            newData.messages.sort((a, b) => {
                return b.timestamp.toDate() - a.timestamp.toDate();
            });
            this.setState({creator: newData, fetchingCreator: false});
        }, (e) => {
            console.error(e);
        });
    }

    resetBodyToDefault = () => {
        
        let body;
        let subject;
        if(this.state.selectedReason == ""){
            body = "";
            subject = "";
        }
        else if(this.state.selectedReason == "accept"){
            subject = `Your Kartfinity level, "` + this.state.level.name + `", is now published!`;
            body = "A moderator has approved your Kartfinity level, \"" + this.state.level.name + ",\" by level creator \"" + this.state.level.creatorName + ".\" Your level may now appear in-game!\n\n";
            if(this.state.editedName != ""){
                body += `The moderator who reviewed your level changed your level's name to "` + this.state.editedName + `." Next time, please make sure your level's name follows community rules.\n\n`;
            }
            if(this.state.editedCreator != ""){
                body += `The moderator who reviewed your level changed your level's creator's name to "` + this.state.editedCreator + `." Next time, please make sure your level's creator's name follows community rules.\n\n`;
            }
            if(this.state.tokensGranted == 1){
                body += "We liked your level so much that we decided to give you your publish token back so that you can keep on creating awesome levels!\n\n";
            }
            else if(this.state.tokensGranted > 0){
                body += "We liked your level so much that we decided to give you " + this.state.tokensGranted + " extra publish tokens, so you can keep on creating awesome levels!\n\n";
            }else if(this.state.tokensGranted < 0){
                body += "Unfortunately, we had to take away " + Math.abs(this.state.tokensGranted) + " publish token" + (this.state.tokensGranted == -1 ? "" : "s") + " from your account.\n\n";
            }
            body += "Show your shiny new level to your friends by sharing the level code:\n" + levelIdify(this.state.levelId);

        }else{
            subject = `Your Kartfinity level, "` + this.state.level.name + `", has been rejected.`;
            body = "Unfortunately, a moderator has rejected your Kartfinity level, \"" + this.state.level.name + ",\" by level creator \"" + this.state.level.creatorName + ".\" Your level cannot be published until changes are made.\n\n";
            body += "We have decided to reject your level because " + rejectionReasons[this.state.selectedReason].reason + ".\n\n";
            if(this.state.tokensGranted == 1){
                body += "However, we have given you your publish token back so you can fix any errors and re-publish the level.\n\n";
            }
            else if(this.state.tokensGranted > 0){
                body += "However, we see this as an opportunity for a great level, so we have rewarded you with " + this.state.tokensGranted + " extra publish tokens, so you can keep on creating awesome levels!\n\n";
            }else if(this.state.tokensGranted < 0){
                body += "Unfortunately, we had to take away " + Math.abs(this.state.tokensGranted) + " publish token" + (this.state.tokensGranted == -1 ? "" : "s") + " from your account to uphold the quality of our levels. However, there are still opportunities to regain these publish tokens by publishing great levels!\n\n";
            } else if(this.state.tokensGranted == 0){
                body += "If you wish, you may fix your level and re-publish it, using another publish token.\n\n";
            }
        }

        this.setState({messageSubject: subject, message: body});

    }

    sendEmail = (callback) => {
        let emailFn = this.functions.httpsCallable("sendEmailMessage");
        if(this.state.creator.emailNotifications == undefined || this.state.creator.emailNotifications == false){
            callback();
            return;
        }
        emailFn({
            to: this.state.creator.email,
            subject: this.state.messageSubject,
            body: this.state.message,
            shareableLevelCode: this.state.levelId
        }).then((res) => {
            console.log(res.data.text);
            callback();
        }, (e) => {
            console.error(e);
            this.setState({error: "email_failed"});
        });
    }

    save = () => {
        this.setState({submitting: true});
        this.db.collection("users").doc(this.state.level.creator).update({
            tokens: firebase.firestore.FieldValue.increment(parseInt(this.state.tokensGranted)),
            messages: firebase.firestore.FieldValue.arrayUnion({
                body: this.state.message,
                subject: this.state.messageSubject,
                tokensGranted: parseInt(this.state.tokensGranted),
                type: (this.state.selectedReason == "accept" ? "moderation/approved" : ("moderation/rejected/" + this.state.selectedReason)),
                timestamp: new Date()
            })
        }).then(() => {
            if(this.state.selectedReason !== "accept"){
                this.db.collection("levels").doc(this.state.levelId).delete().then(() => {
                    this.sendEmail(() => {
                        this.setState({submitting: false});
                        this.fetchLevel();
                    });
                }, (e) => {
                    console.error(e);
                    this.setState({error: "level_delete_failed"});
                });
            }else{
                let updateBody = {
                    modApproved: true
                };
                if(this.state.editedName != ""){
                    updateBody.name = this.state.editedName;
                }
                if(this.state.editedCreator != ""){
                    updateBody.creatorName = this.state.editedCreator;
                }
                this.db.collection("levels").doc(this.state.levelId).update(updateBody).then(() => {
                    this.sendEmail(() => {
                        this.setState({submitting: false});
                        this.fetchLevel();
                    });
                }, (e) => {
                    console.error(e);
                    this.setState({error: "level_update_failed"});
                });
            }

        }, (e) => {
            console.error(e);
            this.setState({error: "user_update_failed"});
        });
        
    }
    
    render(){
        if(this.state.error != "" && this.state.error != null){
            let errorTitle;
            let errorMsg;
            
            switch(this.state.error){
                case "fetch_failed":
                    errorTitle = "Could not get levels";
                    errorMsg = "An unexpected error occured. Please contact " + supportEmail + " for assistance.";
                    break;
                case "user_update_failed":
                    errorTitle = "Could not save";
                    errorMsg = "An unexpected error occured. Please contact " + supportEmail + " for assistance.";
                    break;
                case "level_update_failed":
                    errorTitle = "Could not save";
                    errorMsg = "An unexpected error occured. Please contact " + supportEmail + " for assistance.";
                    break;
                case "level_delete_failed":
                    errorTitle = "Could not save";
                    errorMsg = "An unexpected error occured. Please contact " + supportEmail + " for assistance.";
                    break;
                case "unauthorized":
                    errorTitle = "You do not have permission to view this page.";
                    errorMsg = "This page is only accessible by moderators. If you are a moderator, please make sure you are signed in to the correct account.";
                    break;
                case "no_documents_found":
                    errorTitle = "No more levels to review!";
                    errorMsg = "Hooray! There are no more levels in the review queue. Great job!";
                    break;
                case "email_failed":
                    errorTitle = "Error sending email notification.";
                    errorMsg = "An unexpected error occured. Please contact " + supportEmail + " for assistance.";
            }

            return (
                <>
                    <Helmet>
                        <title>Kartfinity - Moderation</title>
                    </Helmet>
                    <Error inverted title={errorTitle} message={errorMsg} errorCode={this.state.error} />
                </>
            );
        }
        if(this.state.level == null){
            return (
                <>
                    <Helmet>
                        <title>Kartfinity - Moderation</title>
                    </Helmet>
                    <Dimmer active inverted>
                        <Loader inverted />
                    </Dimmer>
                </>
            );
        }
        return (
            <>
            <Helmet>
                <title>Kartfinity - Moderation</title>
            </Helmet>
            <Grid columns="2" style={{width: "100vw", height: "100vh", margin: 0}} inverted>
                <Grid.Row style={{height: "50%"}}>
                    <Grid.Column style={{height: "100%"}}>
                        <Segment inverted className="centered quadrant">
                            <Header as="h2">Level Info</Header>
                            <Table inverted celled style={{fontSize: "20px", textAlign: "center"}}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Property</Table.HeaderCell>
                                        <Table.HeaderCell>Value</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell><b>Level Code</b></Table.Cell>
                                        <Table.Cell>kart-{this.state.levelId}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            <b>Level Name</b>
                                            <Popup on="click" size="huge" trigger={
                                                <Button inverted color="orange" icon="pencil" circular size="mini" style={{marginLeft: "10px"}} />
                                            }>
                                                <Header as="h3">Rename Level</Header>
                                                <Input type="text" placeholder="Rename level..." value={this.state.editedName == "" ? this.state.level.name : this.state.editedName} onChange={ (e, props) => {
                                                    this.setState({editedName: props.value}, () => {
                                                        this.resetBodyToDefault();
                                                    });
                                                }}/>
                                            </Popup>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.state.level.name}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            <b>Creator Name</b>
                                            <Popup on="click" size="huge" trigger={
                                                <Button inverted color="orange" icon="pencil" circular size="mini" style={{marginLeft: "10px"}} />
                                            }>
                                                <Header as="h3">Rename Creator</Header>
                                                <Input type="text" placeholder="Rename creator..." value={this.state.editedCreator == "" ? this.state.level.creatorName : this.state.editedCreator} onChange={ (e, props) => {
                                                    this.setState({editedCreator: props.value}, () => {
                                                        this.resetBodyToDefault();
                                                    });
                                                }}/>
                                            </Popup>
                                        </Table.Cell>
                                        <Table.Cell>{this.state.level.creatorName}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell><b>Published At</b></Table.Cell>
                                        <Table.Cell>{moment(this.state.level.publishedTime.toDate()).format(timestampFormat)}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column style={{height: "100%"}}>
                        <Segment inverted className="centered quadrant">
                            <Header as="h2">Creator Info</Header>
                            {
                                (this.state.creator == null) ? (
                                    <div>
                                        <Divider section inverted />
                                        <Button size="huge" inverted labelPosition="left" icon="user" content="Get Creator Data" primary loading={this.state.fetchingCreator} disabled={this.state.fetchingCreator} onClick={this.fetchCreatorData} />
                                    </div>
                                ) : (
                                    <div>
                                        <Table inverted celled style={{fontSize: "20px", textAlign: "center"}}>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Property</Table.HeaderCell>
                                                    <Table.HeaderCell>Value</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell><b>ID</b></Table.Cell>
                                                    <Table.Cell>{this.state.level.creator}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell><b>Email</b></Table.Cell>
                                                    <Table.Cell>{this.state.creator.email}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell><b>Tokens</b></Table.Cell>
                                                    <Table.Cell>{this.state.creator.tokens}</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <Divider inverted section />
                                        <Header as="h2" inverted>Recent Events</Header>
                                        <List divided relaxed inverted style={{fontSize: "17px", textAlign: "left"}}>
                                            {
                                                this.state.creator.messages.map((message, idx) => {
                                                    return (
                                                        <List.Item key={"message-" + idx}>
                                                            <List.Content>
                                                                <List.Header>{message.subject} <i style={{color: "#949494"}}>{moment(message.timestamp.toDate()).format(timestampFormat)}</i></List.Header>
                                                                <List.Description style={{marginTop: "10px", color: "#949494"}}>{message.type} • {Math.abs(message.tokensGranted)} {Math.abs(message.tokensGranted) == 1 ? "token" : "tokens"} {message.tokensGranted < 0 ? "taken away" : "awarded"}</List.Description>
                                                                <List.Description style={{marginTop: "10px"}}>{
                                                                    message.body.split('\n').map(function(item, key) {
                                                                        return (
                                                                            <span key={"message-" + idx + "-span-" + key}>
                                                                                {item}
                                                                                <br/>
                                                                            </span>
                                                                        )
                                                                    })
                                                                }</List.Description>
                                                            </List.Content>
                                                        </List.Item>
                                                    );
                                                })
                                            }
                                        </List>
                                    </div>
                                )
                            }
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{height: "50%"}}>
                    <Grid.Column style={{height: "100%"}}>
                        <Segment inverted className="centered quadrant">
                            <Header as="h2">Approve/Reject</Header>
                            <Divider inverted section />
                            <List relaxed inverted divided style={{fontSize: "17px", textAlign: "left"}}>
                                <List.Item style={{backgroundColor: this.state.selectedReason == "accept" ? "#4d614d" : "inherit"}} onClick={
                                    () => {
                                        this.setState({selectedReason: "accept"}, () => {
                                            this.resetBodyToDefault();
                                        });
                                    }
                                }>
                                    <List.Content>
                                        <List.Header>Approve level</List.Header>
                                        <List.Description>Everything looks good!</List.Description>
                                    </List.Content>
                                </List.Item>
                                {
                                    Object.keys(this.state.reasons).sort((a, b) => {
                                        return (this.state.reasons[a] ? -1 : 1);
                                    }).map((key) => {
                                        return (
                                            <List.Item key={"reason-" + key} style={{backgroundColor: this.state.selectedReason == key ? "#614d4d" : (this.state.reasons[key] ? "#303023" : "inherit")}} onClick={
                                                () => {
                                                    this.setState({selectedReason: key}, () => {
                                                        this.resetBodyToDefault();
                                                    });
                                                }
                                            }>
                                                <List.Content>
                                                    <List.Header>{rejectionReasons[key].description}</List.Header>
                                                    <List.Description>{key}</List.Description>
                                                </List.Content>
                                            </List.Item>
                                        );
                                    })
                                }
                            </List>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column style={{height: "100%"}}>
                        <Segment inverted className="centered quadrant" style={{position: "relative"}}>
                            <Header as="h2">Message</Header>
                            <Form size="huge" inverted>
                                <Input fluid placeholder="Message subject" value={this.state.messageSubject} onChange={
                                    (e, props) => {
                                        this.setState({messageSubject: props.value});
                                    }
                                } />
                                <Divider hidden />
                                <TextArea placeholder="Message body" value={this.state.message} onChange={
                                    (e, props) => {
                                        this.setState({message: props.value});
                                    }
                                } />
                                <Divider hidden />
                                <Input fluid type="number" placeholder="Tokens to award/take away" value={this.state.tokensGranted} onChange={
                                    (e, props) => {
                                        this.setState({tokensGranted: props.value}, () => {
                                            this.resetBodyToDefault();
                                        });
                                    }
                                } />
                            </Form>
                            <Button
                                inverted
                                labelPosition="left"
                                positive
                                size="huge"
                                icon="check"
                                content="Submit"
                                style={{margin: "auto 0", bottom: "10px", right: "10px", position: "absolute"}}
                                disabled={this.state.selectedReason == "" || this.state.message == "" || this.state.messageSubject == "" || this.state.submitting}
                                loading={this.state.submitting}
                                onClick={this.save}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </>
        );
    }
}