import React from "react";
import StarBG from "./rsc/Stars.jpg";
import "./Home.css";

export default class Interstitial extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className="interstitial" style={{backgroundImage: "url(" + StarBG + ")", color: "#FFFFFF", position: "relative", textAlign: "center", height: (this.props.children == null ? "30vh" : "auto")}}>{this.props.children}</div>
        );
    }
}