import React from "react";
import {Header, Segment} from "semantic-ui-react";

export default class Error extends React.Component {
    render(){
        return (
            <Segment style={{textAlign: "center", height: "100vh", padding: "5%"}} inverted={this.props.inverted}>
                <Header as="h1" inverted={this.props.inverted} style={{fontSize: "32px"}}>{this.props.title}</Header>
                <Header as="h2" inverted={this.props.inverted} style={{fontSize: "20px"}}>{this.props.message}</Header>
                <Header as="h3" inverted={this.props.inverted} style={{color: (this.props.inverted ? "#949494" : "#525252"), fontSize: "16px"}}>{this.props.errorCode}</Header>
            </Segment>
        );
    }
}