import React from "react";
import moment from "moment";
import {Header, Icon} from "semantic-ui-react";

export default class Contest extends React.Component {
    constructor(props){
        super(props);

    }

    render(){
        const timeToStart = this.props.data.start.toDate() - (new Date());
        const timeToEnd = this.props.data.end.toDate() - (new Date());
        const start = moment(this.props.data.start.toDate());
        const end = moment(this.props.data.end.toDate());
        const started = timeToStart <= 0;
        const ended = timeToEnd <= 0;
        return (
            <div id={this.props.anchor ? "contest" : ""} style={{
                border: "15px #FFFFFF solid",
                borderRadius: "30px",
                padding: "50px 5vw 50px 5vw",
                boxShadow: "0 0 30px #FFFFFF"
            }}>
                <Header inverted style={{fontSize: "3vh", fontWeight: "normal"}}>{this.props.titleText}</Header>
                <Header inverted style={{fontSize: "5vh"}}>{this.props.data.title}</Header>
                <p style={{fontSize: "2vh"}}>{this.props.data.description}</p>
                <Header inverted style={{fontSize: "3vh"}}>
                    <Icon name="stopwatch" />
                    {
                        ended ? (
                            "Contest ended " + end.fromNow()
                        ) : (
                            started ? (
                                "Contest ends " + end.fromNow()
                            ) : (
                                "Submissions begin " + start.fromNow()
                            )
                        )
                    }
                </Header>
                {
                    ended ? null : (
                        <Header inverted style={{fontSize: "3vh"}}>
                            <Icon name="upload" />
                            Enter the contest by publishing a level from {start.format("MMMM Do")} to {end.format("MMMM Do")}.
                        </Header>
                    )
                }
                
            </div>
        );
    }

}