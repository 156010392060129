export const firebaseConfig = {
    apiKey: "AIzaSyDGstd2FxmBLUlFTuxFrCCxQxcRr0iuQqA",
    authDomain: "infinitikart.firebaseapp.com",
    databaseURL: "https://infinitikart.firebaseio.com",
    projectId: "infinitikart",
    storageBucket: "infinitikart.appspot.com",
    messagingSenderId: "723854939016",
    appId: "1:723854939016:web:7c3c3d719d59a873"
};

export const supportEmail = "support@kartfinity.com";

export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export function levelIdify(id){
    let res = "kart";
    for(let i = 0; i < id.length; i++){
        if(i % 4 == 0){
            res += "-";
        }
        res += id[i];
    }
    return res;
}