import React from "react";
import {Header} from "semantic-ui-react";
import {Helmet} from "react-helmet";
export default class PrivacyPolicy extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div style={{color: "#FFFFFF", margin: "5vh 5vw 5vh 5vw", fontSize: "15px"}}>
                <Helmet>
                    <title>Kartfinity - Privacy Policy</title>
                </Helmet>
                <Header as="h1" style={{fontSize: "32px", textAlign: "center"}} inverted>Kartfinity Privacy Policy</Header>
                <Header as="h2" inverted>By downloading the Kartfinity game, interacting with our webpage, or signing in/signing up for a Kartfinity account, you agree to this privacy policy. These terms may be changed at any time with or without notification to you.</Header>
                <p>Kartfinity uses Google LLC.'s Firebase service to host its data. Any data we receive will be stored and processed on their servers.</p>
                <p>When you sign up for Kartfinity, we collect your email address, which is associated to your account. This data is not sold to any third party. We also store any levels you publish, some account preferences, and some statistics. We reserve the right to add, delete, alter, or retrieve this data at any time.</p>
                <p>We use Google Analytics to collect data, such as sales, webpage views, and other statistics. We may record broad demographics including but not limited to country, gender, and approximate age. We may use this data to better target our advertisements. You can view Google Analytics' privacy policy by <a href="https://marketingplatform.google.com/about/analytics/terms/us/">clicking here</a>.</p>
                <p>Email notifications can be voluntarily enabled (and disabled) in-game or through our website. If you choose to enable emails, we will send your email to Amazon.com, Inc, whose services we use to send emails. Additionally, we also have marketing emails delivered through The Rocket Science Group LLC d/b/a Mailchimp. You can unsubscribe from these emails at any time by clicking the link in any marketing email</p>
                <p>Email us at hello@kartfinity.com if you have any questions or want to delete your data from our systems.</p>
            </div>
        );
    }
}