import React from "react";
import { Button, Header, Dimmer, Loader, Segment, Divider } from "semantic-ui-react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import {supportEmail} from "./constants";
import Error from "./Error";
import { Helmet } from "react-helmet";
const emailPrefsCloudFunction = "https://us-central1-infinitikart.cloudfunctions.net/setEmailPreferences";

export default class EmailPrefs extends React.Component {
    constructor(props){
        super(props);
        this.state = {notifsEnabled: false, loading: true, error: "", completed: false};
        this.functions = firebase.functions();
        this.db = firebase.firestore();
        firebase.auth().onAuthStateChanged((user) => {
            if(user == null){
                window.location.replace("/auth?dest=%2Femailprefs");
                return;
            }
            this.user = user;
            this.fetchSettings();
        });
    }

    fetchSettings = () => {
        this.db.collection("users").doc(this.user.uid).get().then((doc) => {
            let data = doc.data();
            this.setState({notifsEnabled: data.emailNotifications, loading: false});
        }, (e) => {
            this.setState({error: "fetch_failed", loading: false});
        });
    };

    toggleNotifications = () => {
        this.setState({loading: true});
        this.user.getIdToken().then((token) => {
            const body = this.state.notifsEnabled ? "false" : "true";
            fetch(`${emailPrefsCloudFunction}?idToken=${token}`, {
                method: "POST",
                body,
                headers: {
                    'content-type': 'text/plain'
                }
            }).then((response) => {
                if(!response.ok) {
                    console.error(response.statusCode);
                    this.setState({error: "set_error_status", loading: false});
                    return;
                }
                this.setState({loading: false, notifsEnabled: !this.state.notifsEnabled, completed: true});
            }, (error) => {
                console.error(error);
                this.setState({error: "set_failed", loading: false});
            });
        });
    };

    render(){
        if(this.state.error){
            let errorTitle = "An unknown error occured.";
            let errorMsg = "Something went wrong. Please try again later.";
            switch(this.state.error){
                case "fetch_failed":
                    errorTitle = "Error loading preferences.";
                    errorMsg = "Sorry! There was an error loading your notification preferences. Please try again later, or email " + supportEmail + " for assistance.";
                    break;
                case "set_failed":
                    errorTitle = "Error updating preferences.";
                    errorMsg = "Sorry! There was an error updating your notification preferences. Please try again later, or email " + supportEmail + " for assistance.";
                    break;
                case "set_error_status":
                    errorTitle = "Error updating preferences.";
                    errorMsg = "Sorry! There was an error updating your notification preferences. Please try again later, or email " + supportEmail + " for assistance.";
                    break;
            }
            return (
                <>
                    <Helmet>
                        <title>Kartfinity - Email Preferences</title>
                    </Helmet>
                    <Error inverted title={errorTitle} message={errorMsg} errorCode={this.state.error} />
                </>
            );
        }
        if(this.state.loading){
            return (
                <>
                    <Helmet>
                        <title>Kartfinity - Email Preferences</title>
                    </Helmet>
                    <Dimmer active inverted>
                        <Loader inverted />
                    </Dimmer>
                </>
            );
        }
        if(this.state.completed){
            return (
                <Segment style={{textAlign: "center", height: "100vh", padding: "5%"}} inverted>
                    <Helmet>
                        <title>Kartfinity - Email Preferences Updated</title>
                    </Helmet>
                    <Header as="h1" inverted style={{fontSize: "32px"}}>Email preferences updated!</Header>
                    <Header as="h2" inverted style={{fontSize: "20px"}}>{this.state.notifsEnabled ? "Hooray! You will now get emails from us!" : "You won't recieve any more emails from us."} You can always view your messages in-game.</Header>
                </Segment>
            );
        }

        return (
            <Segment style={{textAlign: "center", height: "100vh", padding: "5%"}} inverted>
                <Helmet>
                    <title>Kartfinity - Email Preferences</title>
                </Helmet>
                <Header as="h1" inverted style={{fontSize: "32px"}}>Email preferences</Header>
                <Header as="h2" inverted style={{fontSize: "20px"}}>
                    We use emails to notify you of your levels' publishing status (e.g. when your level is approved or rejected by moderators). With email notifications turned on, you can get your levels published faster by quickly making changes suggested by moderators.
                    {
                        this.state.notifsEnabled ?
                            (
                                <div>
                                    <Divider inverted section />
                                    <p>Email notifications are on.<br />Don't want emails? Unsubscribe below. You can always view your messages in-game.</p>
                                </div>
                            )
                            :
                            (
                                <div>
                                    <Divider inverted section />
                                    <p>Email notifications are off.<br />Want emails? Subscribe below! You can always view your messages in-game.</p>
                                </div>
                            )
                    }
                </Header>
                <Divider inverted section />
                <Button
                    inverted
                    positive={!this.state.notifsEnabled}
                    negative={this.state.notifsEnabled}
                    size="huge"
                    labelPosition="left"
                    icon={this.state.notifsEnabled ? "dont" : "mail"}
                    content={this.state.notifsEnabled ? "Click to unsubscribe" : "Click to subscribe"}
                    onClick={this.toggleNotifications}
                />
                <Divider section hidden />
                <Header as="h3">Not {this.user.email}?</Header>
                <Button
                    inverted
                    secondary
                    size="medium"
                    labelPosition="left"
                    icon="sign out"
                    content="Change account"
                    onClick={
                        () => {
                            firebase.auth().signOut().then(() => {
                                //redirect should be handled by listener in constructor
                            });
                        }
                    }
                />
            </Segment>
        );
    }
}