import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import "semantic-ui-css/semantic.min.css";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "./constants";

import Moderation from "./Moderation";
import Auth from "./Auth";
import EmailPrefs from "./EmailPrefs";
import Home from "./Home";
import PrivacyPolicy from './PrivacyPolicy';
import { Helmet } from "react-helmet";
export default class App extends React.Component {

	constructor(props) {
		super(props);
		firebase.initializeApp(firebaseConfig);
	}

	render() {
		return (
			<>
				<Helmet>
					<title>Kartfinity</title>
				</Helmet>
				<Router>
					<Route path="/auth" component={Auth} />
					<Route path="/moderation" component={Moderation} />
					<Route path="/emailprefs" component={EmailPrefs} />
					<Route path="/unsubscribe" component={EmailPrefs} />
					<Route path="/privacypolicy" component={PrivacyPolicy} />
					<Route path="/" exact component={Home} />
					<Route path="/home" component={Home} />
				</Router>
			</>
		);
	}
}
