import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {Link} from "react-router-dom";

import {Button, Segment, Input, Divider, Header, Message, Icon, Dimmer, Loader} from "semantic-ui-react";

import Error from "./Error";
import {validateEmail} from "./constants";

export default class Auth extends React.Component {

    constructor(props){
        super(props);
        this.state = {invalidDest: false, loading: false, buttonLoading: false, error: "", username: "", password: ""};
        let url = new URL(window.location.href);
        this.redirectUrl = url.searchParams.get("dest");
        if(this.redirectUrl == "" || this.redirectUrl == undefined){
            //this.state.invalidDest = true;
            this.redirectUrl = "/";
            this.state.loading = false;
            return;
        }
    }

    signIn = () => {
        this.setState({buttonLoading: true});
        if(this.state.password == "" || !validateEmail(this.state.username)){
            this.setState({error: "Email or password incorrect. Please check your login info and try again.", buttonLoading: false});
            return;
        }
        firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password).then((user) => {
            window.location.replace(this.redirectUrl);
        }, (e) => {
            console.error(e);
            switch(e.code){
                case "auth/invalid-email":
                    this.setState({error: "The email you entered is invalid. Check for mistakes and try again.", buttonLoading: false});
                    break;
                case "auth/user-disabled":
                    this.setState({error: "Your account has been disabled. Please contact us for assistance.", buttonLoading: false});
                    break;
                case "auth/user-not-found":
                    this.setState({error: "There is no account associated with that email. You can sign up in-game.", buttonLoading: false});
                    break;
                case "auth/wrong-password":
                    this.setState({error: "The password you entered is incorrect. Check for mistakes and try again.", buttonLoading: false});
                    break;
                default:
                    this.setState({error: "An unknown error occured. Please try again later.", buttonLoading: false});
                    break;
            }
        });   
    };

    render(){
        if(this.state.loading){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }
        if(this.state.invalidDest){
            return (
                <Error title="Invalid Parameter" message={
                    (
                        <span>This probably happened because you directly navigated to this page. If so, salutations, brave explorer! You may want to <Link to="/">return home</Link>.</span>
                    )
                } errorCode="invalid_dest" />
            );
        }
        return (
            <div className="page" style={{textAlign: "center", paddingTop: "10vh"}}>
                <Header as="h1" icon size="huge"><Icon name="sign in" />Welcome!</Header>
                <Divider hidden style={{marginTop: "30px"}} />
                <Segment compact style={{margin: "0 auto", padding: "20px 5vw"}}>
                    <Header as="h2" size="huge">Sign In to Kartfinity</Header>
                    <Divider />
                    <Input placeholder="Email" disabled={this.state.buttonLoading} error={!validateEmail(this.state.username)} size="huge" type="email" value={this.state.username} onChange={(e, props) => {
                        this.setState({username: props.value});
                    }} />
                    <Divider hidden />
                    <Input placeholder="Password" disabled={this.state.buttonLoading} error={this.state.password == ""} size="huge" type="password" value={this.state.password} onChange={(e, props) => {
                        this.setState({password: props.value});
                    }} />
                    <Divider />
                    <Button size="huge" primary fluid loading={this.state.buttonLoading} content="Sign In" onClick={this.signIn}/>
                    {
                        this.state.error == "" ? null : (
                            <Message error icon="x" content={this.state.error} />
                        )
                    }
                </Segment>
                
            </div>
        );
    }
}